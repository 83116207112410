import Urls from 'common/urls'
import { ExternalLink } from 'components/links'
import { graphql, useStaticQuery } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import React, { FC } from 'react'
import { Left, Paragraph, Right, Title, Container } from './styled'
import { Section } from '../styled'

type FluidImage = {
  childImageSharp: {
    fluid: FluidObject
  }
}

type QueryResult = {
  heroDesktop: FluidImage
}

const query = graphql`
  query {
    heroDesktop: file(
      relativePath: { eq: "clinical-efficiency-doc-desktop.webp" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1800, webpQuality: 70) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const ClinicalEfficiency: FC = () => {
  const { heroDesktop } = useStaticQuery<QueryResult>(query)

  return (
    <Section>
      <Container>
        <Left>
          <Title>Where clinical efficiency meets clinical excellence</Title>
          <Paragraph>
            <span translate="no">Fellow&apos;s </span>CLIA-certified diagnostics
            lab combines the ease of a mail-in semen analysis with peer-reviewed
            clinical performance to provide clinically valid results<sup>§</sup>
            in less than 2 business days from when a specimen reaches our
            laboratory.
          </Paragraph>
          <ExternalLink to={Urls.leadGenForm} inverse>
            Get in touch
          </ExternalLink>
        </Left>
        <Right>
          <Img
            fluid={heroDesktop.childImageSharp.fluid}
            imgStyle={{ objectFit: 'contain' }}
            loading="eager"
            fadeIn={false}
          />
        </Right>
      </Container>
    </Section>
  )
}

export default ClinicalEfficiency
